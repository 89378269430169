export const constants = {
    peopleAxis: '(PEOPLE)',
    executionAxis: '(EXECUTION)',
    inputsAxis: 'INPUTS',
    outputsAxis: 'OUTPUTS',
    strategy: 'STRATEGY',
    tactics: 'TACTICS',
    momentum: 'MOMENTUM INDEX',
    doubleArrow: '>>',
    motivation: 'Motivation',
    vision: 'Vision',
    roadmap: 'Roadmap',
    capability: 'Capability',
    vitality: 'Vitality',
    commitment: 'Commitment',
    confidence: 'Confidence',
    aligment: 'Aligment',
    people: 'People',
    problems: 'Problems',
    relationships: 'Relationships',
    clarity: 'Clarity',
    efficiency: 'Efficiency',
    throughput: 'Throughput',
    prioritization: 'Prioritization',
    quality: 'Quality',
    flow: 'Flow',
    growth: 'Growth',
    tools: 'Tools',
    skills: 'Skills',
    required: 'The values must be between 0 and 4',
    reset: 'RESET'
}