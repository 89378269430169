import { constants } from './constants';
import { Component, OnInit } from '@angular/core';
import { SingleDataSet, Label } from 'ng2-charts';
import { ChartType } from 'chart.js';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'my-app',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.css' ]
})
export class AppComponent implements OnInit {

  momentumForm: FormGroup;
  // Default value for form.
  initialState: object;

  constants = constants;
  // PolarArea - keep this order to have 'People' at first element (positive X axis and positive Y axis)
  polarAreaChartLabels: Label[] = [
    'People', 'Problems', 'Relationships', 'Clarity', //vision
    'Efficiency', 'Throughput', 'Prioritization', 'Quality', //roadmap
    'Flow', 'Growth', 'Tools', 'Skills', //capability
    'Vitality', 'Commitment', 'Confidence', 'Aligment'  //motivation
    ];

  // Range from 4 - 8 (values from 0 to 3 will be inside circle with average values)
  polarAreaChartData: SingleDataSet = [8,8,8,8,8,8,8,8,8,8,8,8,8,8,8,8];

  polarAreaLegend = true;
  polarAreaChartType: ChartType = 'polarArea';
  // Don't modify this options. This affects polar chart canvas
  options = {
    scale: {
        ticks: {
          min: 0,
          max: 12,
          maxTicksLimit: 1,
          display: false
        },
        angleLines: {
          display: false
        }
    },
    legend: {
      display: false
    },
    elements: {
      point:{
          radius: 0
      }
    }
  };

  // Styles for polar chart canvas
  polarAreaChartColors: Array<any> = [
    {
      backgroundColor: [
        'rgb(175, 190, 73, 0.3)',
        'rgb(175, 190, 73, 0.3)',
        'rgb(175, 190, 73, 0.3)',
        'rgb(175, 190, 73, 0.3)',
        'rgb(56, 147, 163, 0.3)',
        'rgb(56, 147, 163, 0.3)',
        'rgb(56, 147, 163, 0.3)',
        'rgb(56, 147, 163, 0.3)',
        'rgb(277, 77, 50, 0.3)',
        'rgb(277, 77, 50, 0.3)',
        'rgb(277, 77, 50, 0.3)',
        'rgb(277, 77, 50, 0.3)',
        'rgb(152, 119, 178, 0.3)',
        'rgb(152, 119, 178, 0.3)',
        'rgb(152, 119, 178, 0.3)',
        'rgb(152, 119, 178, 0.3)',
      ],
      borderColor: "rgba(0, 0, 0, 0.1)",
      borderWidth: 1,
      hoverBorderColor: "rgba(0, 0, 0, 0.4)"
    }
  ];

  // Set all variables to calculate averages
  motivationAverage: number = 0;
  visionAverage: number = 0;
  capabilityAverage: number = 0;
  roadmapAverage: number = 0;
  strategyAverage: number = 0;
  tacticsAverage: number = 0;
  momentumIndex: number = 0;

  // Calculate average for cuadrant
  private calculateAverage = (val1:number,val2:number,val3:number,val4:number):number => {
    return (val1 + val2 + val3 + val4)/4;
  }

  // Calculate all values from app
  private loadData = (values:Object) => {
    this.visionAverage = this.calculateAverage(values['people'],values['problems'],values['relationships'],values['clarity']);
    this.roadmapAverage = this.calculateAverage(values['efficiency'],values['throughput'],values['prioritization'],values['quality']);
    this.capabilityAverage = this.calculateAverage(values['flow'],values['growth'],values['tools'],values['skills']);
    this.motivationAverage = this.calculateAverage(values['vitality'],values['commitment'],values['confidence'],values['aligment']);

    this.strategyAverage = this.motivationAverage * this.visionAverage;
    this.tacticsAverage = this.capabilityAverage * this.roadmapAverage;
   
    this.momentumIndex = this.strategyAverage * this.tacticsAverage;

    this.loadChartData(values);
  }

  // Set new values for canvas. Plus 4 is to keep values range.
  private loadChartData = (values:Object) => {
    this.polarAreaChartData = [];
    this.polarAreaChartData.push(values['people'] + 4);
    this.polarAreaChartData.push(values['problems'] + 4);
    this.polarAreaChartData.push(values['relationships'] + 4);
    this.polarAreaChartData.push(values['clarity'] + 4);
    this.polarAreaChartData.push(values['efficiency'] + 4);
    this.polarAreaChartData.push(values['throughput'] + 4);
    this.polarAreaChartData.push(values['prioritization'] + 4);
    this.polarAreaChartData.push(values['quality'] + 4);
    this.polarAreaChartData.push(values['flow'] + 4);
    this.polarAreaChartData.push(values['growth'] + 4);
    this.polarAreaChartData.push(values['tools'] + 4);
    this.polarAreaChartData.push(values['skills'] + 4);
    this.polarAreaChartData.push(values['vitality'] + 4);
    this.polarAreaChartData.push(values['commitment'] + 4);
    this.polarAreaChartData.push(values['confidence'] + 4);
    this.polarAreaChartData.push(values['aligment'] + 4);
  }

  // Set form. Save form init value on 'initialState'.
  ngOnInit(){
    this.momentumForm = new FormGroup({
      // motivation
      vitality: new FormControl(4, [Validators.min(0), Validators.max(4), Validators.required]),
      commitment: new FormControl(4, [Validators.min(0), Validators.max(4), Validators.required]),
      confidence: new FormControl(4, [Validators.min(0), Validators.max(4), Validators.required]),
      aligment: new FormControl(4, [Validators.min(0), Validators.max(4), Validators.required]),
      // vision
      people: new FormControl(4, [Validators.min(0), Validators.max(4), Validators.required]),
      problems: new FormControl(4, [Validators.min(0), Validators.max(4), Validators.required]),
      relationships: new FormControl(4, [Validators.min(0), Validators.max(4), Validators.required]),
      clarity: new FormControl(4, [Validators.min(0), Validators.max(4), Validators.required]),
      // roadmap
      efficiency: new FormControl(4, [Validators.min(0), Validators.max(4), Validators.required]),
      throughput: new FormControl(4, [Validators.min(0), Validators.max(4), Validators.required]),
      prioritization: new FormControl(4, [Validators.min(0), Validators.max(4), Validators.required]),
      quality: new FormControl(4, [Validators.min(0), Validators.max(4), Validators.required]),
      // capability
      flow: new FormControl(4, [Validators.min(0), Validators.max(4), Validators.required]),
      growth: new FormControl(4, [Validators.min(0), Validators.max(4), Validators.required]),
      tools: new FormControl(4, [Validators.min(0), Validators.max(4), Validators.required]),
      skills: new FormControl(4, [Validators.min(0), Validators.max(4), Validators.required])    
    });
    this.initialState = this.momentumForm.value;
    this.loadData(this.momentumForm.value);
  }

  // Roung to 2 decimals and update values when actual input lost focus
  round = (event: any, control:string) => {
    if (event.target.value !== ''){
      let inputValue = Number(event.target.value);
      event.target.value = Number(inputValue.toFixed(2));
      this.momentumForm.value[control] = Number(inputValue.toFixed(2));
    }    
    if (this.momentumForm.status === 'VALID'){
      this.loadData(this.momentumForm.value);
    }
  }

  // Set form as default values.
  resetForm = () => {
    this.momentumForm.reset(this.initialState);
    this.loadData(this.momentumForm.value);
  }
}