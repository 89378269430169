<div class="axis-container">
  <span class="axis y-top-axis">{{constants.strategy}} <span class="comment">{{constants.peopleAxis}}</span></span>
  <span class="axis y-bottom-axis">{{constants.tactics}} <span class="comment">{{constants.executionAxis}}</span></span>
  <span class="axis x-left-axis">{{constants.inputsAxis}}</span>
  <span class="axis x-right-axis">{{constants.outputsAxis}}</span>
  <div class="motivation-image"></div>
  <div class="vision-image"></div>
  <div class="capability-image"></div>
  <div class="roadmap-image"></div>
  <span class="motivation-average">{{motivationAverage | number : '1.2-2'}}</span>
  <span class="vision-average">{{visionAverage | number : '1.2-2'}}</span>
  <span class="capability-average">{{capabilityAverage | number : '1.2-2'}}</span>
  <span class="roadmap-average">{{roadmapAverage | number : '1.2-2'}}</span>
  <canvas baseChart class="base-chart"
    [data]="polarAreaChartData"
    [labels]="polarAreaChartLabels"
    [legend]="polarAreaLegend"
    [chartType]="polarAreaChartType"
    [colors]="polarAreaChartColors"
    [options]="options">
  </canvas>
  <div class="axis-image"></div>
  <div class="right-column">
    <div class="double-arrow"></div>
    <div class="block">
      <div class="block-content">
        <span>{{constants.strategy}}</span><br>
        <span>{{strategyAverage | number : '1.2-2'}}</span>
      </div>
    </div>
    <div class="block middle-block">
      <div class="block-content">
        <span>{{constants.momentum}}</span><br>
        <span>{{momentumIndex | number : '1.2-2'}}</span>
      </div>      
    </div>
    <div class="block">
      <div class="block-content">
        <span>{{constants.tactics}}</span><br>
        <span>{{tacticsAverage | number : '1.2-2'}}</span>
      </div>      
    </div>
  </div>
</div>
<div class="form-container">
  <form [formGroup]="momentumForm">
    <div class="column column-vision">
      <div class="form-vision-image"></div>
        <mat-form-field>
          <mat-label for="people">{{constants.people}}</mat-label>
          <input matInput type="number" formControlName="people" min="0" max="4" (blur)="round($event, 'people')">
          <mat-error *ngIf="momentumForm.controls.people.invalid">{{constants.required}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label for="problems">{{constants.problems}}</mat-label>
          <input matInput type="number" formControlName="problems" min="0" max="4" (blur)="round($event, 'problems')">
          <mat-error *ngIf="momentumForm.controls.problems.invalid">{{constants.required}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label for="relationships">{{constants.relationships}}</mat-label>
          <input matInput type="number" formControlName="relationships" min="0" max="4" (blur)="round($event, 'relationships')">
          <mat-error *ngIf="momentumForm.controls.relationships.invalid">{{constants.required}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label for="clarity">{{constants.clarity}}</mat-label>
          <input matInput type="number" formControlName="clarity" min="0" max="4" (blur)="round($event, 'clarity')">
          <mat-error *ngIf="momentumForm.controls.clarity.invalid">{{constants.required}}</mat-error>
        </mat-form-field>
      </div>
    <div class="column column-motivation">
      <div class="form-motivation-image"></div>
      <mat-form-field>
        <mat-label for="vitality">{{constants.vitality}}</mat-label>
        <div lang="en-EU">
        <input matInput type="number" formControlName="vitality" min="0" max="4" (blur)="round($event, 'vitality')">
        </div>
        <mat-error *ngIf="momentumForm.controls.vitality.invalid">{{constants.required}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label for="commitment">{{constants.commitment}}</mat-label>
        <input matInput type="number" formControlName="commitment" min="0" max="4" (blur)="round($event, 'commitment')">
        <mat-error *ngIf="momentumForm.controls.commitment.invalid">{{constants.required}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label for="confidence">{{constants.confidence}}</mat-label>
        <input matInput type="number" formControlName="confidence" min="0" max="4" (blur)="round($event, 'confidence')">
        <mat-error *ngIf="momentumForm.controls.confidence.invalid">{{constants.required}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label for="aligment">{{constants.aligment}}</mat-label>
        <input matInput type="number" formControlName="aligment" min="0" max="4" (blur)="round($event, 'aligment')">
        <mat-error *ngIf="momentumForm.controls.aligment.invalid">{{constants.required}}</mat-error>
      </mat-form-field>
    </div>    
    <div class="column column-capability">
      <div class="form-capability-image"></div>
      <mat-form-field>
        <mat-label for="flow">{{constants.flow}}</mat-label>
        <input matInput type="number" formControlName="flow" min="0" max="4" (blur)="round($event, 'flow')">
        <mat-error *ngIf="momentumForm.controls.flow.invalid">{{constants.required}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label for="growth">{{constants.growth}}</mat-label>
        <input matInput type="number" formControlName="growth" min="0" max="4" (blur)="round($event, 'growth')">
        <mat-error *ngIf="momentumForm.controls.growth.invalid">{{constants.required}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label for="tools">{{constants.tools}}</mat-label>
        <input matInput type="number" formControlName="tools" min="0" max="4" (blur)="round($event, 'tools')">
        <mat-error *ngIf="momentumForm.controls.tools.invalid">{{constants.required}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label for="skills">{{constants.skills}}</mat-label>
        <input matInput type="number" formControlName="skills" min="0" max="4" (blur)="round($event, 'skills')">
        <mat-error *ngIf="momentumForm.controls.skills.invalid">{{constants.required}}</mat-error>
      </mat-form-field>
    </div>
    <div class="column column-roadmap">
      <div class="form-roadmap-image"></div>
        <mat-form-field>
          <mat-label for="efficiency">{{constants.efficiency}}</mat-label>
          <input matInput type="number" formControlName="efficiency" min="0" max="4" (blur)="round($event, 'efficiency')">
          <mat-error *ngIf="momentumForm.controls.efficiency.invalid">{{constants.required}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label for="throughput">{{constants.throughput}}</mat-label>
          <input matInput type="number" formControlName="throughput" min="0" max="4" (blur)="round($event, 'throughput')">
          <mat-error *ngIf="momentumForm.controls.throughput.invalid">{{constants.required}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label for="prioritization">{{constants.prioritization}}</mat-label>
          <input matInput type="number" formControlName="prioritization" min="0" max="4" (blur)="round($event, 'prioritization')">
          <mat-error *ngIf="momentumForm.controls.prioritization.invalid">{{constants.required}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label for="quality">{{constants.quality}}</mat-label>
          <input matInput type="number" formControlName="quality" min="0" max="4" (blur)="round($event, 'quality')">
          <mat-error *ngIf="momentumForm.controls.quality.invalid">{{constants.required}}</mat-error>
        </mat-form-field>
      </div>
  </form>
  <div class="button-container">
    <button type="button" class="btn close" (click)="resetForm()">
      {{constants.reset}}
      <span class="reset-icon"></span>
    </button>
  </div>
</div>